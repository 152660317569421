<template>
  <div>
    <Menus />

    <div class="container mt-4">
      <h3>Envio de Arquivos</h3>

      <form @submit.prevent="submitFile" class="mt-3">
        <div class="form-group mb-3">
          <label>Produto:</label>
          <select v-model="form.product" required class="form-control">
            <option disabled value="">Selecione o produto</option>
            <option v-for="produto in produtos" :key="produto.value" :value="produto.value">
              {{ produto.label }}
            </option>
          </select>
        </div>

        <div class="form-group mb-3">
          <label>Consignante:</label>
          <select v-model="form.consignor_id" required class="form-control">
            <option disabled value="">Selecione o consignante</option>
            <option v-for="consignante in consignantes" :key="consignante.id" :value="consignante.id">
              {{ consignante.name }}
            </option>
          </select>
        </div>

        <div class="form-group mb-3">
          <label>Mês/Ano de Referência:</label>
          <flat-pickr
            v-model="form.referenceMonthYear"
            :config="config"
            class="form-control"
            required
          />
        </div>

        <div class="form-group mb-3">
          <label>Arquivo:</label>
          <input type="file" @change="handleFile" required class="form-control" />
        </div>

        <button type="submit" class="btn btn-primary" :disabled="isUploading">
          {{ isUploading ? "Enviando..." : "Enviar Arquivo" }}
        </button>

        <div class="progress mt-3" v-if="isUploading">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: progress + '%' }"
            :aria-valuenow="progress"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ progress }}%
          </div>
        </div>
      </form>

      <div v-if="uploadStatus" class="alert alert-info mt-3">{{ uploadStatus }}</div>

      <div class="mt-5">
        <h4>Arquivos Enviados</h4>
        <FileTable :arquivos="arquivos" @abrirCritica="abrirCritica" />
      </div>

      <CritiqueModal
        v-if="modalCritica"
        :critica="criticaSelecionada"
        @close="modalCritica = false"
      />
    </div>
  </div>
</template>

<script>
import Menus from "@/components/Menus.vue";
import FileTable from "@/components/FileTable.vue";
import CritiqueModal from "@/components/CritiqueModal.vue";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.min.css";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

const baseUrl = process.env.VUE_APP_BASE_URL;

export default {
  components: { Menus, FileTable, CritiqueModal, flatPickr },
  data() {
    return {
      produtos: [],
      form: {
        product: "",
        consignor_id: "",
        referenceMonthYear: null,
        file: null,
      },
      config: {
        locale: Portuguese,
        dateFormat: "m/Y",
        plugins: [
          new monthSelectPlugin({
            shorthand: false,
            dateFormat: "m/Y",
            altFormat: "F Y",
          }),
        ],
      },
      consignantes: [],
      uploadStatus: "",
      arquivos: [],
      modalCritica: false,
      criticaSelecionada: "",
      isUploading: false,
      progress: 0,
    };
  },
  methods: {
    handleFile(event) {
      const file = event.target.files[0];
      if (file && file.size < 5242880) {
        this.form.file = file;
      } else {
        alert("Arquivo inválido ou maior que 5MB");
        event.target.value = "";
      }
    },
    async loadProdutos() {
      try {
        const response = await axios.get(`${baseUrl}v2/my-products`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });

        const data = Array.isArray(response.data)
          ? response.data
          : [response.data];

        this.produtos = data.map((item) => ({
          label: item.ds_valor,
          value: item.ds_chave.toString(),
        }));
      } catch (error) {
        console.error("Erro ao carregar produtos:", error);
      }
    },
    async submitFile() {
      try {
        this.isUploading = true;
        this.progress = 0;

        const token = localStorage.getItem("authToken");
        if (!token) {
          alert("Token não encontrado. Faça login novamente.");
          return;
        }

        const formData = new FormData();
        formData.append("product", this.form.product);
        formData.append("consignor_id", this.form.consignor_id);

        // ✅ Ajustar formato para Y-m
        const [month, year] = this.form.referenceMonthYear.split("/");
        const formattedReference = `${year}-${month}`;
        formData.append("referenceMonthYear", formattedReference);

        formData.append("file", this.form.file);

        await axios.post(`${baseUrl}v2/file-uploads`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            this.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        });

        this.uploadStatus = "Arquivo enviado com sucesso!";
        this.resetForm();
        this.getArquivos();
      } catch (error) {
        console.error(
          "Erro ao enviar arquivo:",
          error.response?.data || error.message
        );
        this.uploadStatus = "Erro ao enviar arquivo.";
      } finally {
        this.isUploading = false;
        this.progress = 0;
      }
    },
    resetForm() {
      this.form = {
        product: "",
        consignor_id: "",
        referenceMonthYear: "",
        file: null,
      };
    },
    async getArquivos() {
      try {
        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("Token não encontrado. Usuário não autenticado.");
          return;
        }

        const response = await axios.get(`${baseUrl}v2/file-uploads`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        this.arquivos = response.data;
      } catch (error) {
        console.error(
          "Erro ao buscar arquivos:",
          error.response?.data || error.message
        );
      }
    },
    async getConsignantes() {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          console.error("Token não encontrado.");
          return;
        }

        const response = await axios.get(`${baseUrl}v2/my-consignor`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        this.consignantes = response.data.data;
      } catch (error) {
        console.error(
          "Erro ao buscar consignantes:",
          error.response?.data || error.message
        );
      }
    },
    abrirCritica(critica) {
      this.criticaSelecionada = critica;
      this.modalCritica = true;
    },
  },
  mounted() {
    this.loadProdutos();
    this.getArquivos();
    this.getConsignantes();
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
}

/* Customização Month Select */
.flatpickr-monthSelect-months {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
}

.flatpickr-monthSelect-month {
  width: 70px;
  padding: 6px 8px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.flatpickr-monthSelect-month:hover {
  background-color: #1384ad;
  color: #fff;
}

.flatpickr-monthSelect-month.selected {
  background-color: #1384ad;
  color: #fff;
}
</style>