<template>
  <div>
    <b-navbar class="j-navbar" toggleable="lg">
      <div class="j-navbar-header">
        <b-navbar-brand :href="url">
          <b-img src="img/logobranca.png"></b-img>
        </b-navbar-brand>
      </div>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse class="navbar-collapse collapse" id="nav-collapse" is-nav>
        <b-navbar-nav class="j-nav--menu">

          <template v-for="item in menuItems">

            <template v-if="item.submenus && item.submenus.length > 0">

              <b-nav-item-dropdown :key="item.cd_menu" :text="item.nm_menu" class="dropdown">
                <template #button-content>
                  <div>
                    <font-awesome-icon v-if="item.icone && item.icone.trim()" :icon="item.icone" />

                    {{ item.nm_menu }}
                  </div>
                </template>
                <b-dropdown-item class=" " v-for="submenu in item.submenus" :key="submenu.cd_menu"
                                 :href="url + submenu.ds_url">
                  {{ submenu.nm_menu }}
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </template>
            <template v-else>
              <!-- Usar o b-nav-item quando não houver submenus ou a condição não for atendida -->
              <b-nav-item class="white-text" :key="item.cd_menu" :href="url+item.ds_url">
                <font-awesome-icon v-if="item.icone && item.icone.trim()" :icon="item.icone" />

                {{ item.nm_menu }}
              </b-nav-item>
            </template>
          </template>
        </b-navbar-nav>
      </b-collapse>
      <div class="j-cx-info">
        <div class="text-right" style="color: white" id="div_info_login">
          <table>
            <tbody>
            <tr>
              <td>
                <div class="usuario">Olá
                  <strong>{{ nome }}</strong><br>
                  Usuário: {{ user }}
                </div>
                <div class="dados_usuario">
                  Último Acesso: {{ ultimoAcesso }} <br>
                  <span class="local">{{ local }}</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-navbar>
    <div class="cx_dados">

      <div class="j-right">
        <a class="btn btn-xs btn-link" :href="`${url}usuario/perfil/cod/1`"> <font-awesome-icon :icon="['fas', 'address-card']" /> Cadastro</a>
        <a class="btn btn-xs btn-link" :href="`${url}/v2/#/trocar-senha`"><font-awesome-icon :icon="['fas', 'key']" />
          Senha</a>
        <a class="btn btn-xs btn-j-logout" href="#" @click="sair()"><font-awesome-icon :icon="['fas', 'sign-out-alt']" /> Sair</a>

      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

const baseUrlInfo = process.env.VUE_APP_INFOCONSIG_BASE_URL;
//const baseUrlInfo = '/#/acesso-menu/';

const baseUrl = process.env.VUE_APP_BASE_URL;
export default {
  name: 'MyUsers',

  data() {
    return {
      nome: "",
      user: "",
      ultimoAcesso: "",
      local: "",
      menuItems: [],
      url: baseUrlInfo
    };
  },
  mounted() {


    this.fetchMenuItems();

  },


  methods: {

    fetchMenuItems() {
      const authToken = localStorage.getItem('authToken');

      const headers = {
        'Authorization': `Bearer ${authToken}`
      };

      axios.get(baseUrl + 'grupo', {headers: headers})
          .then(response => {
            //console.log(response.data);
            this.menuItems = response.data;

            console.log(this.menuItems[0].nm_menu);
          })
          .catch(error => {
            console.error('Erro ao obter os dados do menu:', error);
          });
      axios.get(baseUrl + 'user-detalhe', {headers: headers})
          .then(response => {
            console.log(response.data)
            this.nome = response.data.nome,
                this.user = response.data.usuario,
                this.ultimoAcesso = response.data.ultimo_acesso,
                this.local = response.data.local

          })
          .catch(error => {
            console.error('Erro ao obter os dados do menu:', error);
          });
    },
    async sair() {
      const authToken = localStorage.getItem('authToken');

      if (authToken) {
        try {
          const response = await axios.post(baseUrl+'v2/logout', null, {
            headers: {
              'Authorization': 'Bearer ' + authToken
            }
          });

          console.log(response.data);
          //location.reload();
          // Faça qualquer redirecionamento ou ação necessária após o logout.
          window.location.href = '/';
        } catch (error) {
          console.error('Erro no logout:', error);
          window.location.href = '/';
         // location.reload();
        }
      } else {
        console.log('Token de autenticação não encontrado.');
      }

      localStorage.removeItem("pessoa");
      //localStorage.removeItem("token");
    }

  }

};


</script>

<style src="@/assets/layout.css">
/* Add custom styles if needed *

 */
span{
  color: white;
}
span.local{
  color: white;
}
.local {
  color:white;
}
</style>
