<template>
    <div>
      <Menus />
  
      <div class="container mt-4">
        <h3>Gerenciamento de Arquivos</h3>
  
        <!-- Filtros -->
        <div class="row mb-3">
            <div class="col-md-3">
                <v-select
                  v-model="filters.consignor"
                  :options="consignors"
                  label="name"
                  :reduce="consignor => consignor.id"
                  placeholder="Selecione a Consignante"
                />
              </div>
              <div class="col-md-3">
                <v-select
                  v-model="filters.consignee"
                  :options="consignees"
                  label="name"
                  :reduce="consignee => consignee.id"
                  placeholder="Selecione a ConsignatáriaConsignante"
                />
              </div>
              <div class="col-md-3">
                <select v-model="filters.product" class="form-control">
                  <option value="">Produto</option>
                  <option v-for="produto in produtos" :key="produto.value" :value="produto.value">
                    {{ produto.label }}
                  </option>
                </select>
              </div>
          <div class="col-md-3">
            <select v-model="filters.status" class="form-control">
              <option value="">Status</option>
              <option v-for="status in statuses" :key="status.value" :value="status.value">
                {{ status.label }}
              </option>
            </select>
          </div>
          <div class="col-md-3 mt-2">
            <input v-model="filters.referenceMonthYear" type="month" class="form-control" />
          </div>
          <div class="col-md-3 d-flex align-items-end mt-2">
            <button class="btn btn-primary w-100" @click="fetchUploads" :disabled="loading">
              {{ loading ? 'Carregando...' : 'Filtrar' }}
            </button>
          </div>
        </div>
  
        <!-- Tabela -->
        <table class="table table-striped">
          <thead>
            <tr style="color: white; background-color: #1384AD">
              <th>Consignatária</th>
             <th>Usuário</th>
              <th>Consignante</th>
              <th>Produto</th>
              <th>Mês/Ano</th>
              <th>Status</th>
              <th>Enviado em</th>
              <th>Arquivo Critica</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="upload in uploads" :key="upload.id">
              <td>{{ upload.consignne }}</td>
              
              <td>{{ upload.user }}</td>
              <td>{{ upload.consignor }}</td>
              <td>{{ formatProduct(upload.product) }}</td>
              <td>{{ padZero(upload.reference_month) }}/{{ upload.reference_year }}</td>
              <td> <span :class="statusClass(upload.status)">
                {{ formatStatus(upload.status) }}
              </span></td>
              <td>{{ upload.created_at }}</td>
              <td>
                <a v-if="upload.critical_file" :href="getDownloadUrl(upload.critical_file)" target="_blank" class="btn btn-outline-primary btn-sm">Arquivo Critica</a>
              </td>
              <td>
                <a :href="getDownloadUrl(upload.file_path)" target="_blank" class="btn btn-outline-primary w-100 btn-sm">Download</a>
<br>
                <button class="btn btn-sm btn-warning mr-1 w-100" @click="editUpload(upload)">Alterar</button>
                <button class="btn btn-sm btn-info w-100" @click="viewLogs(upload)">Histórico</button>
              </td>
            </tr>
          </tbody>
        </table>
  
        <!-- Modais -->
        <!-- <FileUploadModal v-if="showModal" :upload="selectedUpload" @close="showModal = false" /> -->

        <FileUploadModal 
  v-if="showModal" 
  :upload="selectedUpload" 
  @close="handleModalClose" 
/>

        <FileUploadLogs v-if="showLogs" :upload="selectedUpload" @close="showLogs = false" />
      </div>
    </div>
  </template>
  
  <script>
  import FileUploadLogs from "@/components/FileUploadLogs.vue";
  import FileUploadModal from "@/components/FileUploadModal.vue";
  import Menus from "@/components/Menus.vue";
  import axios from "axios";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";

  
  const baseUrl = process.env.VUE_APP_BASE_URL;
  
  export default {
    components: { Menus, FileUploadModal, FileUploadLogs,vSelect },
    data() {
      return {
        filters: {
          consignor: "",
          consignee: "",
          product: "",
          status: "",
          referenceMonthYear: "",
        },
        produtos: [],
        statuses: [
          { label: "Não Visualizado", value: "not_viewed" },
          { label: "Visualizado", value: "viewed" },
          { label: "Acatado", value: "approved" },
          { label: "Acatado Parcialmente", value: "partially_approved" },
          { label: "Não Acatado", value: "rejected" },
        ],
        statusClass(status) {
      switch (status) {
        case 'approved':
          return 'badge badge-success';
        case 'partially_approved':
          return 'badge badge-warning';
        case 'rejected':
          return 'badge badge-danger';
        default:
          return 'badge badge-secondary';
      }
    },
        uploads: [],
        consignors: [],
        consignees: [],
        showModal: false,
        showLogs: false,
        selectedUpload: null,
        loading: false,
      };
    },
    methods: {
      async fetchUploads() {
        try {
          this.loading = true;
          const token = localStorage.getItem("authToken");
          if (!token) {
            alert("Token não encontrado.");
            return;
          }
  
          const params = {};
  
          if (this.filters.consignor) {
            params["filter[consignor_id]"] = this.filters.consignor;
          }
          if (this.filters.consignee) {
            params["filter[consignee_id]"] = this.filters.consignee;
          }
          if (this.filters.product) {
            params["filter[type_consignment]"] = this.filters.product;
          }
          if (this.filters.status) {
            params["filter[status]"] = this.filters.status;
          }
          if (this.filters.referenceMonthYear) {
            params["filter[reference_month_year]"] = this.filters.referenceMonthYear;
          }
  
          const response = await axios.get(`${baseUrl}v2/admin/file-uploads`, {
            headers: { Authorization: `Bearer ${token}` },
            params: params,
          });
  
          this.uploads = response.data.data;
        } catch (error) {
          console.error("Erro ao buscar uploads:", error.response?.data || error.message);
        } finally {
          this.loading = false;
        }
      },

      async loadProdutos() {
    try {
      const response = await axios.get(`${baseUrl}v2/admin/my-products`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      this.produtos = response.data.map(item => ({
        label: item.ds_valor,
        value: item.ds_chave
      }));
    } catch (error) {
      console.error('Erro ao carregar produtos:', error);
    }
  },
      async fetchConsignors() {
        try {
          const token = localStorage.getItem("authToken");
          if (!token) {
            alert("Token não encontrado.");
            return;
          }
  
          const response = await axios.get(`${baseUrl}v2/admin/consignantes`, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          this.consignors = response.data.data;
        } catch (error) {
          console.error("Erro ao buscar consignatárias:", error.response?.data || error.message);
        }
      },
      async fetchConsignees() {
        try {
          const token = localStorage.getItem("authToken");
          if (!token) {
            alert("Token não encontrado.");
            return;
          }
  
          const response = await axios.get(`${baseUrl}v2/admin/consignatarias`, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          this.consignees = response.data.data;
        } catch (error) {
          console.error("Erro ao buscar consignantes:", error.response?.data || error.message);
        }
      },
      getConsignorName(id) {
        const consignor = this.consignors.find(c => c.id === id);
        return consignor ? consignor.name : "-";
      },
      getConsigneeName(id) {
        const consignee = this.consignees.find(c => c.id === id);
        return consignee ? consignee.name : "-";
      },
      formatProduct(product) {
        const found = this.produtos.find(p => p.value === product);
        return found ? found.label : product;
      },
      formatStatus(status) {
        const found = this.statuses.find(s => s.value === status);
        return found ? found.label : status;
      },
      padZero(value) {
        return value.toString().padStart(2, "0");
      },
      viewFile(upload) {
        window.open(`${upload.file_path}`, "_blank");
      },
      editUpload(upload) {
        this.selectedUpload = upload;
        this.showModal = true;
      },
      viewLogs(upload) {
        this.selectedUpload = upload;
        this.showLogs = true;
      },
      getDownloadUrl(path) {
      return `${path}`;
    },
    handleModalClose() {
    this.showModal = false;
    this.fetchUploads();
  },
    },
    mounted() {
      this.loadProdutos();
      this.fetchUploads();
      this.fetchConsignors();
      this.fetchConsignees();
    },
  };
  </script>
  
