<template>
    <div class="vh-100 d-flex flex-column justify-content-center align-items-center bg-white px-3">
      <!-- LOGO -->
      <div class="text-center mb-4">
        <img src="img/logo.png" alt="Logo" style="max-width: 300px;" class="mb-3" />
        <h4 class="fw-semibold text-dark mb-1">Selecione seu Perfil</h4>
        <p class="text-muted small">Confirme seu perfil para acessar</p>
      </div>
  
      <!-- CARD -->
      <div class="card shadow border-0 w-100" style="max-width: 460px;">
        <div class="card-header text-white text-center" style="background-color: #1384AD;">
          <h5 class="mb-0">Perfis Disponíveis</h5>
        </div>
        <div class="card-body">
          <!-- Loading -->
          <div v-if="loading" class="text-center my-4">
            <div class="spinner-border mb-3" :style="{ color: '#1384AD' }" role="status"></div>
            <p class="text-muted">Buscando perfis...</p>
          </div>
  
          <!-- Nenhum perfil -->
          <div v-else-if="userList.length === 0" class="text-center text-secondary my-4">
            <i class="bi bi-info-circle fs-1 mb-2" style="color: #1384AD;"></i>
            <p>Nenhum perfil encontrado.</p>
          </div>
  
          <!-- Lista de perfis -->
          <div v-else>
            <div class="form-check mb-3" v-for="user in userList" :key="user.id">
              <input
                class="form-check-input"
                type="radio"
                :value="user.id"
                v-model="selectedUser"
                :id="'user-' + user.id"
              />
              <label class="form-check-label fw-semibold" :for="'user-' + user.id">
                {{ user.name }} <small class="text-muted">({{ user.group.name }})</small>
              </label>
            </div>
  
            <button
              class="btn w-100 mt-3 text-white fw-bold"
              :disabled="!selectedUser"
              @click="selectUser"
              :style="{
                backgroundColor: selectedUser ? '#1384AD' : '#6c757d',
                borderColor: selectedUser ? '#1384AD' : '#6c757d'
              }"
            >
              Acessar Sistema
            </button>
          </div>
        </div>
      </div>
  
      <!-- FOOTER -->
      <footer class="mt-4 text-muted small">
        <p>&copy; 2025 - Sttórico Sistemas Ltda</p>
      </footer>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  const baseUrl = process.env.VUE_APP_BASE_URL;
  const infoUrl = process.env.VUE_APP_INFOCONSIG_BASE_URL;
  
  export default {
    name: 'MyUsers',
    data() {
      return {
        userList: [],
        selectedUser: null,
        loading: true,
       // authToken: localStorage.getItem('authToken')
      };
    },
    async mounted() {
      this.$termoUso();
      await this.fetchMyUserData();
      this.$consultaUsuario();
    },
    methods: {
      async fetchMyUserData() {
        try {
          const authToken = localStorage.getItem('authToken');
          if (!authToken) {
            this.$router.push('/');
            return;
          }
  
          const response = await axios.post(baseUrl + 'v2/me', null, {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          });
  
          if (response.data.length === 1) {
            const user = response.data[0];
            localStorage.setItem('cd_usuario', user.id);
            document.cookie = `pessoa=${user.id}; path=/`;
            document.cookie = `token=${this.authToken}; path=/`;
            location.href = infoUrl + "home";
          } else {
            this.userList = response.data.data || [];
          }
        } catch (error) {
          console.error('Erro ao buscar perfis:', error);
        } finally {
          this.loading = false;
        }
      },
  
      selectUser() {
        if (this.selectedUser) {
         const authToken = localStorage.getItem('authToken');
          localStorage.setItem('cd_usuario', this.selectedUser);
          this.setUserLegado(this.selectedUser);
         document.cookie = `pessoa=${this.selectedUser}; path=/`;
         document.cookie = `token=${authToken}; path=/`;
         this.goHome();
        }
      },
  
      goHome() {
        location.href = `${infoUrl}home`;
      },
  
      setUserLegado(userId) {
        const authToken = localStorage.getItem('authToken');
        const url = `${baseUrl}v2/selected-user-legacy`;
  
        axios.post(url, null, {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            userId: userId
          }
        })
        .then(() => {
          this.goHome();
        })
        .catch(error => {
          console.error('Erro ao setar usuário legado:', error);
        });
      }
    }
  };
  </script>
  
  <style scoped>
  footer {
    opacity: 0.7;
  }
  </style>