import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginForm from '../components/LoginForm.vue';
import Myusers from '../components/MyUsers.vue';
import TermoAceite from "@/components/TermoAceite.vue";
import ValidaForm from "@/components/ValidaForm.vue";
import SenhaForm from "@/components/SenhaForm.vue";
import EsqueceuSenha from "@/components/EsqueceuSenha.vue";
import PasswordForm from "@/components/PasswordForm.vue";
import NewPasswordForm from "@/components/NewPasswordForm.vue";
import Menus from "@/components/Menus.vue";
import Simulador from "@/components/Simulador.vue";
import AgendamentoPesquisa from "@/views/AgendamentoPesquisa.vue"
import CadastroQuestoes from "@/views/CadastroQuestoes.vue";
import TesteIp from "@/components/TesteIp.vue";
import SolicitacaoProposta from "@/views/SolicitacaoProposta.vue";
import RefinFromProposta from "@/components/RefinFromProposta.vue";
import SolicitacaoSaldoEmprestimo from "@/components/SolicitacaoSaldoEmprestimo.vue";
import UploadFileBanco from "@/components/UploadFileBanco.vue";
import GraficoMargem from "@/components/GraficoMargem.vue";
import ConsultaProcesso from "@/components/ConsultaProcesso.vue";
import DashboardBI from "@/components/DashboardBI.vue";
import ExtratoFinaceiro from "@/components/ExtratoFinaceiro.vue";
import AcessoLog from "@/components/AcessoLog.vue";
import ControleAcessoMenu from "@/components/ControleAcessoMenu.vue";
import MargemCancela from "@/components/MargemCancela.vue";
import Diretorio from "@/components/Diretorio.vue";
import GestaoMenu from "@/components/GestaoMenu.vue";
import TrocarSenha from "@/components/TrocarSenha.vue";
import SlidePage from "@/components/SlidePage.vue";
import UploadFile from '@/views/UploadFile.vue';
import AdminFileUploads from '@/views/AdminFileUploads.vue';


Vue.use(VueRouter);

const routes = [
    {path: '/', component: LoginForm, name: 'Home'},
    {path: '/myusers', component: Myusers, name: 'MyUsers'},
    {path: '/termoaceite', component: TermoAceite, name: 'TermoAceite'},
    {path: '/valida', component: ValidaForm, name: 'ValidaForm'},
    {path: '/primeirasenha', component: SenhaForm, name: 'SenhaForm'},
    {path: '/forget-password', component: EsqueceuSenha, name: 'EsqueceuSenha'},
    {path: '/password', component: PasswordForm, name: 'PasswordForm'},
    {path: '/newpassword', component: NewPasswordForm, name: 'NewPasswordForms'},
    {path: '/agendamento-pesquisa', component: AgendamentoPesquisa, name: 'AgendamentoPesquisa'},
    {path: '/ts', component: CadastroQuestoes, name: 'CadastroQuestoes'},
    {path: '/reset-password/:token/:email', component: NewPasswordForm, name: 'NewPasswordForm'},
    {path: '/menus', component: Menus, name: 'Menu'},
    {path: '/simulador', component: Simulador, name: 'Simulador'},
    {path: '/testeip', component: TesteIp, name: 'TesteIp'},
    {path: '/solicitacao-proposta/:cd_proposta', component: SolicitacaoProposta, name: 'SolicitacaoProposta'},
    {path: '/refin-from-proposta/:cd_proposta', component: RefinFromProposta, name: 'RefinFromProposta'},
    {path: '/saldo-emprestimo/:cd_proposta', component: SolicitacaoSaldoEmprestimo, name: 'SolicitacaoSaldoEmprestimo'},
    {path: '/upload-file-banco/:cd_proposta', component: UploadFileBanco, name: 'UploadFileBanco'},
    {path: '/margem/:cd_proposta', component: GraficoMargem, name: 'GraficoMargem'},
    {path: '/consulta-judicial', component: ConsultaProcesso, name: 'ConsultaProcesso'},
    {path: '/dashboard', component: DashboardBI, name: 'DashboardBI'},
    {path: '/extrato-financeiro', component: ExtratoFinaceiro, name: 'ExtratoFinaceiro'},
    {path: '/acesso-log', component: AcessoLog, name: 'AcessoLog'},
    {path: '/acesso-menu/:cd_menu', component: ControleAcessoMenu, name: 'ControleAcessoMenu'},
    {path: '/solicitacoes', component: MargemCancela, name: 'MargemCancela'},
    {path: '/diretorio', component: Diretorio, name: 'Diretorio'},
    {path: '/gestao-menu', component: GestaoMenu, name: 'GestaoMenu'},
    {path: '/slides', component: SlidePage, name: 'SlidePage'},
    {path: '/trocar-senha', component: TrocarSenha, name: 'TrocarSenha'},
    {path: '/upload-file', component: UploadFile, name: 'UploadFile'},
    { path: '/admin/file-uploads', component: AdminFileUploads, name: 'AdminFileUploads' }





];

const router = new VueRouter({
    routes
});
router.beforeEach(async (to, from, next) => {
    const authToken = localStorage.getItem('authToken');
    const pessoa = localStorage.getItem('pessoa');
  
    // Rotas públicas
    const publicRoutes = [
      'Home', 'EsqueceuSenha', 'ValidaForm', 'PasswordForm', 'NewPasswordForm',
      'Menu', 'TesteIp', 'SolicitacaoProposta', 'RefinFromProposta',
      'SolicitacaoSaldoEmprestimo', 'ConsultaProcesso'
    ];
  
    if (!authToken && !pessoa && !publicRoutes.includes(to.name)) {
      return next({ name: 'Home' });
    }
    if (to.name === 'UploadFile') {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}user-detalhe`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Erro ao buscar dados do usuário');
        }
  
        const data = await response.json();
  
        if (!data.group || data.group.id != 61) {
          alert('Acesso não autorizado!');
          return next({ name: 'Home' });
        }
      } catch (error) {
        console.error('Erro ao validar grupo do usuário:', error);
        return next({ name: 'Home' });
      }
    }
  
    return next();
  });
export default router;
