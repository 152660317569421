<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <h4>Editar Status e Crítica</h4>

      <form @submit.prevent="submit">
        <div class="form-group mb-3">
          <label>Status:</label>
          <select v-model="form.status" required class="form-control">
            <option disabled value="">Selecione o status</option>
            <option v-for="status in statuses" :key="status.value" :value="status.value">
              {{ status.label }}
            </option>
          </select>
        </div>

        <div class="form-group mb-3">
          <label>Crítica:</label>
          <textarea v-model="form.review_note" class="form-control" rows="3"></textarea>
        </div>

        <div class="form-group mb-3">
          <label>Arquivo Crítico:</label>
          <input type="file" class="form-control" @change="handleFileChange" />
        </div>

        <div v-if="progress > 0" class="form-group mb-3">
          <div class="progress">
            <div class="progress-bar" :style="{ width: progress + '%' }">
              {{ progress }}%
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-secondary mr-2" @click="$emit('close')">Cancelar</button>
          <button type="submit" class="btn btn-primary">Salvar</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

export default {
  props: {
    upload: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        status: this.upload.status,
        review_note: this.upload.review_note || "",
      },
      statuses: [
        { label: "Não Visualizado", value: "not_viewed" },
        { label: "Visualizado", value: "viewed" },
        { label: "Acatado", value: "approved" },
        { label: "Acatado Parcialmente", value: "partially_approved" },
        { label: "Não Acatado", value: "rejected" },
      ],
      criticalFile: null,
      progress: 0,
    };
  },
  methods: {
    handleFileChange(event) {
      this.criticalFile = event.target.files[0];
    },
    async submit() {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          alert("Token não encontrado. Faça login novamente.");
          return;
        }

        const formData = new FormData();
        formData.append('_method', 'PATCH'); // importante!
        formData.append('status', this.form.status);
        formData.append('review_note', this.form.review_note);
        if (this.criticalFile) {
          formData.append('critical_file', this.criticalFile);
        }

        await axios.post(`${baseUrl}v2/admin/file-uploads/${this.upload.id}/status`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        });

        alert("Status e arquivo atualizados com sucesso!");
        this.$emit("close");
      } catch (error) {
        console.error("Erro ao atualizar status:", error.response?.data || error.message);
        alert("Erro ao atualizar status.");
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
}

.progress {
  height: 20px;
  background: #e9ecef;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: #1384ad;
  color: white;
  text-align: center;
  transition: width 0.4s ease;
}
</style>