<template>
    <div class="modal-overlay">
      <div class="modal-content">
        <h4>Histórico de Alterações</h4>
  
        <table class="table table-striped mt-3">
          <thead>
            <tr style="color: white; background-color: #1384AD">
              <th>Admin</th>
              <th>Ação</th>
              <th>Dados</th>
              <th>Data/Hora</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="log in logs" :key="log.id">
                <td>{{ log.user }}</td>
                <td>{{ log.action }}</td>
                <td>
                  <ul class="list-none p-0 m-0">
                    <li v-if="log.dados.status">
                      <strong>Status:</strong> {{ log.dados.status }}
                    </li>
                    <li v-if="log.dados.review_note">
                        <strong>Critica do Arquivo:</strong> {{ log.dados.review_note }}
                      </li>
                    <li v-if="log.dados.updated_at">
                      <strong>Atualizado em:</strong> {{ log.dados.updated_at }}
                    </li>
                  </ul>
                </td>
                <td>{{ log.created_at }}</td>
              </tr>
          </tbody>
        </table>
  
        <div class="d-flex justify-content-end mt-3">
          <button type="button" class="btn btn-secondary" @click="$emit('close')">Fechar</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  const baseUrl = process.env.VUE_APP_BASE_URL;
  
  export default {
    props: {
      upload: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        logs: [],
      };
    },
    methods: {
      async fetchLogs() {
        try {
          const token = localStorage.getItem("authToken");
          if (!token) {
            alert("Token não encontrado. Faça login novamente.");
            return;
          }
  
          const response = await axios.get(`${baseUrl}v2/admin/file-uploads/${this.upload.id}/logs`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          this.logs = response.data;
        } catch (error) {
          console.error("Erro ao buscar logs:", error.response?.data || error.message);
        }
      },
      formatData(data) {
        return JSON.stringify(data, null, 2);
      },
    },
    mounted() {
      this.fetchLogs();
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 700px;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  </style>
  