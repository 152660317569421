import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery';
import VueRouter from 'vue-router';
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Inputmask from 'inputmask';
import VueSweetalert2 from 'vue-sweetalert2';
import VueTheMask from 'vue-the-mask';
import {VueRecaptcha} from "vue-recaptcha";


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import {termoUso, consultaUsuario} from "@/utils/global";


import 'sweetalert2/dist/sweetalert2.min.css';
import Multiselect from "vue-multiselect";

document.title = process.env.VUE_APP_PAGE_TITLE || 'Título Padrão';


Vue.prototype.$consultaUsuario = consultaUsuario;
Vue.prototype.$termoUso = termoUso;

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

const options = {
    confirmButtonColor: '#1384AD',
    cancelButtonColor: '#ff7674',
};

Vue.use(VueRouter);
Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.component('vue-recaptcha', VueRecaptcha);
Vue.component('MultiSelect', Multiselect)



Vue.directive('mask-reais', {
    inserted: function(el) {
        const inputmask = new Inputmask({
            mask: '999.99',
            radixPoint: ',',
            groupSeparator: '.',
            autoGroup: true,
            digits: 2,
            digitsOptional: true,
            rightAlign: false,
            allowMinus: false,
            placeholder: '0',
            onBeforeMask: function(value) {
                return value.replace('.', ',');
            },
            radixFocus: true // Começa pelas casas decimais
        });

        inputmask.mask(el);
    }
});
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueSweetalert2, options);


Vue.config.productionTip = false;


new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
