<template>
  <div class="custom-modal-backdrop">
    <div class="modal fade show d-block" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" style="background-color: #1384AD; color: white;">
            <h5 class="modal-title">Detalhes da Crítica</h5>
            <button type="button" class="close" @click="$emit('close')">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ critica }}</p>

            <div v-if="critical_file">
              <h6>Arquivo Crítico:</h6>
              <a :href="getDownloadUrl(critical_file)" target="_blank" class="btn btn-outline-primary btn-sm">
                Ver Arquivo
              </a>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    critica: {
      type: String,
      required: true,
    },
    critical_file: {
      type: String,
      default: "",
    },
  },
  methods: {
    getDownloadUrl(path) {
      if (!path) return "#";
    //const baseUrl = process.env.VUE_APP_BASE_URL;
      return `${path}`;
    },
  },
};
</script>

<style scoped>
.custom-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.modal {
  display: block;
  z-index: 1055;
}
</style>