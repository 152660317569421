<template>
  <div>
    <!-- Tabela -->
    <table class="table table-striped mt-3">
      <thead>
        <tr style="color: white; background-color: #1384AD">
          <th>Arquivo</th>
          <th>Consignante</th>
          <th>Usuário</th>
          <th>Produto</th>
          <th>Mês/Ano</th>
          <th>Status</th>
          <th>Data de Envio</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="arquivo in arquivos" :key="arquivo.id">
          <td>
            <a :href="getDownloadUrl(arquivo.file_path)" target="_blank" class="btn btn-outline-primary btn-sm">Download</a>
          </td>
          <td>{{ arquivo.consignor }}</td>
          <td>{{ arquivo.user }}</td>
          <td>{{ formatProduct(arquivo.product) }}</td>
          <td>{{ padZero(arquivo.reference_month) }}/{{ arquivo.reference_year }}</td>
          <td>
            <span :class="statusClass(arquivo.status)">
              {{ formatStatus(arquivo.status) }}
            </span>
          </td>
          <td>{{ arquivo.created_at }}</td>
          <td>
            <button
              v-if="arquivo.review_note"
              class="btn btn-outline-primary btn-sm"
              @click="abrirCritica(arquivo.review_note, arquivo.critical_file)"
            >
              Ver Crítica
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal Critica -->
    <div v-if="showModalCritica" class="custom-modal-backdrop">
      <div class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header" style="background-color: #1384AD; color: white;">
              <h5 class="modal-title">Detalhes da Crítica</h5>
              <button type="button" class="close" @click="fecharModal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>{{ critica }}</p>

              <div v-if="criticalFile">
                <h6>Arquivo Crítico:</h6>
                <a :href="getDownloadUrl(criticalFile)" target="_blank" class="btn btn-outline-primary btn-sm">
                  Download
                </a>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="fecharModal">Fechar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    arquivos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModalCritica: false,
      critica: "",
      criticalFile: "",
    };
  },
  methods: {
    abrirCritica(reviewNote, criticalFile) {
      this.critica = reviewNote;
      this.criticalFile = criticalFile;
      this.showModalCritica = true;
    },
    fecharModal() {
      this.showModalCritica = false;
      this.critica = "";
      this.criticalFile = "";
    },
    statusClass(status) {
      switch (status) {
        case 'approved':
          return 'badge badge-success';
        case 'partially_approved':
          return 'badge badge-warning';
        case 'rejected':
          return 'badge badge-danger';
        default:
          return 'badge badge-secondary';
      }
    },
    formatStatus(status) {
      switch (status) {
        case 'approved':
          return 'Acatado';
        case 'partially_approved':
          return 'Acatado Parcialmente';
        case 'rejected':
          return 'Não Acatado';
        case 'viewed':
          return 'Visualizado';
        case 'not_viewed':
          return 'Não Visualizado';
        default:
          return status;
      }
    },
    formatProduct(product) {
      switch (product) {
        case 'credit_card':
          return 'Cartão de Crédito';
        case 'benefit_withdrawal':
          return 'Carta de Benefício Saque';
        case 'benefit_purchase':
          return 'Cartão Benefício Compra';
        case 'general_monthly_fee':
          return 'Mensalidade Geral';
        case 'various_agreements':
          return 'Convênios Diversos';
        default:
          return product;
      }
    },
    padZero(value) {
      return value.toString().padStart(2, '0');
    },
    getDownloadUrl(path) {
      if (!path) return '#';
      //const baseUrl = process.env.VUE_APP_BASE_URL;
      return `${path}`;
    },
  },
};
</script>

<style scoped>
.badge {
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
}

.badge-success {
  background-color: green;
}

.badge-warning {
  background-color: orange;
}

.badge-danger {
  background-color: red;
}

.badge-secondary {
  background-color: gray;
}

.custom-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.modal {
  display: block;
  z-index: 1055;
}
</style>